<template>
  <div class="content ">

    <div class="text-center bg" style="  background-image:  linear-gradient(to top, black, transparent) !important;">
            <img
              v-if="festival.metadata.main_image"
              v-lazy="festival.metadata.main_image.imgix_url+'?h=230&q=&fit=clamp&w=386'"
              width="500px"
              style="
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                margin-bottom: 20px;
                max-width: 300px;
              "
              :alt="festival.name"
            />
      <br />
      <img
        v-if="festival.metadata.logo"
        v-lazy="festival.metadata.logo.imgix_url"
        height="20px"
        style="margin-bottom: 20px; max-width: 300px"
        :alt="festival.name"
      />
      <div class="header text-center" style="margin-bottom: -10px">
        <h1 class="tulum-party-title" style="color: #ffffff !important;font-size: 1.1rem">
           HORYZON TULUM
          <br>
          2022
        </h1>
        <hr>
      </div>
      <div
        class="card"
        style="color: #fff !important; margin-bottom: 1px; padding: 10px"
      >
        <p>Horyzon Festival is a set of several parties taking place from October 28th to November 19th in three diverse venues around TULUM.
          A combination of Tulum's Jungle, the beach, and the nightlife in Tulum converge together at Horyzon Festival.
          These four weekends will present a multi-format of music with artists from around the world.</p>
        <div v-html="festival.content"></div>
      </div>
      <hr>
      <div class="text-center" style="padding: 60px" >
        <h2
          style="
            margin-top: 1px;
            font-weight: bold;
            font-size: 1.5rem;
            text-transform: uppercase;
          "
          class="tulum-party-subtitle;"
          v-if="festivalParties.length"
        >
          Event Line
        </h2>

        <br />
        <time-line>
          <time-line-item
            v-for="(event, idx) in festivalParties"
            :key="event._id"
            :inverted="idx % 2 === 1"
            :badge-type="badgeType(idx)"
            :badge-icon="badgeIcon(idx)"
          >
            <div slot="content" class="card-link">
              <EventCard
                v-bind:event="event"
                :key="event._id"
                :idx="idx"
              ></EventCard>
            </div>
          </time-line-item>
        </time-line>
        <br />
        <br />
        Check the full list of upcoming events @ Tulum Mexico
        <br>
        <br>
        <router-link :to="{ name: 'Home' }" class="btn btn-info ">
          MORE EVENTS
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { TimeLine, TimeLineItem } from 'src/components';
import EventCard from '../../components/EventCard/EventCard';

export default {
  name: 'HoryzonTulum2022',
  created: function () {
    this.$store.dispatch('getFestival', { slug: this.slug });
  },
  props: ['slug'],
  metaInfo() {
    let title = this.festival
      ? this.festival.title
      : 'Tulum Festivals';
    return {
      title: `HORYZON FEST 2022. Events @ Tulum Mexico`,
      meta: [
        {
          name: 'description',
          content: `HORYZON FEST 2022. Tickets & Information.`,
        },
        {
          name: 'keywords',
          content: `Tulum Festival`,
        },
        {
          property: 'og:title',
          content: `HORYZON FEST 2022. Tickets & Information`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `HORYZON FEST 2022. Events @ Tulum Mexico`,
        },
        { property: 'og:type', content: 'article' },
        // {
        //   property: 'og:image',
        //   content: this.festival.metadata.logo && this.festival.metadata.logo.imgix_url
        // }
      ],
    };
  },
  data: () => {
    return {};
  },
  components: {
    TimeLine,
    TimeLineItem,
    EventCard,
  },

  methods: {
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },
  computed: {
    festivalParties: {
      get() {
        return (
          this.$store.state.festival.metadata.parties || [
            { title: '', metadata: { main_image: {}, date: null, } },
          ]
        );
      },
    },
    festival: {
      get() {
        return this.$store.state.festival;
      },
    },
  },
};
</script>
<style lang="scss">
body {
  //background: linear-gradient(to top, black, #23d5ab) !important;

}
.bg {


}
</style>

